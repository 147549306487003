<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <!-- <el-form-item label="姓名:">
            <el-input v-model="selectForm.person" placeholder="姓名"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="意向岗位:">
            <el-input v-model="selectForm.sketch" placeholder="意向岗位"></el-input>
          </el-form-item> -->
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
        <el-table-column label="姓名" prop="Name"></el-table-column>
        <el-table-column prop="NativePlace" label="籍贯"> </el-table-column>
        <el-table-column label="意向岗位" prop="Positions"></el-table-column>
        <el-table-column label="面试时间" prop="date" :formatter="formatStartTime"></el-table-column>
        <el-table-column label="面试状态" prop="InterviewState"></el-table-column>
        <el-table-column label="面试结论" prop="InterviewConclusion"></el-table-column>
        <!--<el-table-column label="录用情况" prop="AdjustDate"></el-table-column>-->
        <!-- <el-table-column label="备注" prop="Id"></el-table-column> -->
        <el-table-column label="录用进度" prop="ProcessType"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper ,total" :page-count="total" :total="totaldata"></el-pagination>
    <el-dialog :title="operation ? '面试' : '面试'" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false">
      <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label=" 面试通知ID:" prop="interviewExtentId">
              <el-input type="text" :disabled="true" v-model="SakaryForm.interviewExtentId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label=" 面试者姓名:" prop="Name">
              <el-input type="text" :disabled="true" v-model="SakaryForm.Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label=" ApplyId:" prop="ApplyId">
              <el-input type="text" :disabled="true" v-model="SakaryForm.ApplyId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="面试时间:" prop="date" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm.date" value-format="yyyy-MM-dd" placeholder="选择面试时间"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="涉及⼈员" label-width="120px">
              <el-button @click="chooseUserList()">选择涉及⼈员</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table :data="SakaryForm.ExaminerArry" style="width: 100%" class="bg-color">
            <el-table-column prop="Content" label="面试内容"></el-table-column>
            <el-table-column prop="InterviewType" label="面试内容类型" v-if="false"></el-table-column>
            <el-table-column prop="UserName" label="面试官"></el-table-column>
            <el-table-column label="操作" width="150">
              <template scope="scope">
                <el-button size="small" v-on:click="PersonsDelete(scope.row)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- <el-row>
          <el-col :span="24" style="text-align: right">
            <el-form-item label-width="0px">
              <el-button type="primary" v-on:click="SalainterBtn()">组织面试</el-button>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoose :data="choosedusers" :all="true" :key="key" :single="false" @callback="usercallFunction"></UserChoose>
    </el-dialog>
    <el-dialog :visible.sync="addUserListVisible" v-model="addUserListVisible" center append-to-body>
      <el-form ref="SakaryUserForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="24" style="display:none;">
            <el-form-item label=" 面试内容:" prop="content" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryUserForm.content"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="面试内容类型:" prop="InterviewType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
            <el-select v-model="SakaryUserForm.InterviewType" placeholder="请选择" style="“width: 100%”" @change="selectInterviewType()">
              <el-option label="基本要求面试" value="1"></el-option>
              <el-option label="专业技能操作" value="2"></el-option>
              <el-option label="专业知识" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="24" style="display:none;">
            <el-form-item label=" 面试官ID:" prop="UserId">
              <el-input type="text" :disabled="true" v-model="SakaryUserForm.UserId"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="面试官:" prop="userName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" :disabled="true" v-model="SakaryUserForm.userName">
                <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
            <el-form-item label-width="0px">
              <el-button type="primary" v-on:click="onUserSubmit()">确认选择</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 面试官评价开始 -->
    <el-dialog :title="operation3 ? '新增面试官评价' : '修改面试官评价'" :visible.sync="SakaryVisible3" v-model="SakaryVisible3" :close-on-click-modal="false">
      <el-form ref="SakaryForm1" :model="SakaryForm1" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="24">
            基本要求面试评价:
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label=" 面试通知ID:" prop="interviewExtentId">
              <el-input type="text" :disabled="true" v-model="SakaryForm1.interviewExtentId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label=" 面试官评价ID:" prop="Id">
              <el-input type="text" :disabled="true" v-model="SakaryForm1.Id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label=" 职业发展观:" prop="CareerDevelopment">
              <el-input type="text" v-model="SakaryForm1.CareerDevelopment" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label=" 抗压能力:" prop="StressTolerance">
              <el-input type="text" v-model="SakaryForm1.StressTolerance" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label=" 沟通表达方面:" prop="Communication">
              <el-input type="text" v-model="SakaryForm1.Communication" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="基本要求符合度:" prop="Result">
              <el-select v-model="SakaryForm1.Result" placeholder="请选择" style="“width: 100%”" :disabled="disabled">
                <el-option label="非常符合" value="非常符合"></el-option>
                <el-option label="符合" value="符合"></el-option>
                <el-option label="不符合" value="不符合"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            专业部分面试:
          </el-col>
        </el-row>
        <el-row>
          <el-table :data="SakaryForm1.DetailArry" style="width: 100%" border>
            <el-table-column label="名称" prop="name" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
                <!-- <el-input type="text" :placeholder="scope.row.name" v-model="scope.row.name"/> -->
              </template>
            </el-table-column>
            <el-table-column label="测试项目" prop="Testitem" align="center">
              <template slot-scope="scope">
                <span v-show="scope.row.isShow2">{{ scope.row.Testitem }}</span>
                <el-input type="text" :placeholder="scope.row.Testitem" v-model="scope.row.Testitem" v-show="scope.row.isShow1"/>
              </template>
            </el-table-column>
            <el-table-column label="分数" prop="Score" align="center">
              <template slot-scope="scope">
                <span v-show="scope.row.isShow2">{{ scope.row.Score }}</span>
                <!-- <el-input-number :controls="false" size="small" type="text" :placeholder="scope.row.Score" v-model="scope.row.Score" v-show="!scope.row.isShow"/> -->
                <el-input type="text" :placeholder="scope.row.Score" v-model="scope.row.Score" v-show="scope.row.isShow1"/>
              </template>
            </el-table-column>
            <el-table-column label="评价" prop="Appraise" align="center">
              <template slot-scope="scope">
                <span v-show="scope.row.isShow2">{{ scope.row.Appraise }}</span>
                <el-input type="text" :placeholder="scope.row.Appraise" v-model="scope.row.Appraise" v-show="scope.row.isShow1"/>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit1(scope.$index, scope.row)" v-show="scope.row.isShow">编辑</el-button>
                <el-button  @click="hold(scope.$index, scope.row)" size="mini" v-show="scope.row.isShow1">保存</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit3('SakaryForm1')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 面试官评价结束 -->

    <!-- 用人部门评价开始 -->
    <el-dialog :title="operation4 ? '新增用人部门评价' : '修改用人部门评价'" :visible.sync="SakaryVisible4" v-model="SakaryVisible4" :close-on-click-modal="false">
      <el-form ref="SakaryForm2" :model="SakaryForm2" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="24">
            基本要求面试评价:
          </el-col>
        </el-row>
        <el-row>
          <!--<el-col :span="12">
            <el-form-item label=" 面试通知ID:" prop="interviewExtentId">
              <el-input type="text" :disabled="true" v-model="SakaryForm2.interviewExtentId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label=" 用人部门评价ID:" prop="Id">
              <el-input type="text" :disabled="true" v-model="SakaryForm2.Id"></el-input>
            </el-form-item>
          </el-col>-->
          <el-col :span="24">
            <el-form-item label=" 职业发展观:" prop="CareerDevelopment">
              <el-input type="text" :disabled="true" v-model="SakaryForm2.CareerDevelopment"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label=" 抗压能力:" prop="StressTolerance">
              <el-input type="text" :disabled="true" v-model="SakaryForm2.StressTolerance"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label=" 沟通表达方面:" prop="Communication">
              <el-input type="text" :disabled="true" v-model="SakaryForm2.Communication"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="基本要求符合度:" prop="Result">
              <el-input type="text" :disabled="true" v-model="SakaryForm2.Result"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            专业部分面试:
          </el-col>
        </el-row>
        <el-row>
          <el-table :data="SakaryForm2.DetailArry" style="width: 100%" class="bg-color">
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="Testitem" label="测试项目"></el-table-column>
            <el-table-column prop="Score" label="分数"></el-table-column>
            <el-table-column prop="Appraise" label="评价"></el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-col :span="24">
              <el-form-item label=" 用人部门面试总结:" prop="Summary">
                  <el-input type="textarea" v-model="SakaryForm2.Summary" />
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否通过:" prop="isHire" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <!-- <el-input type="text" v-model="SakaryForm1.Result"></el-input> -->
              <el-radio-group v-model="SakaryForm2.isHire" class="ml-4">
                <el-radio :label="true" size="small">是</el-radio>
                <el-radio :label="false" size="small">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit4('SakaryForm2')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 用人部门评价结束 -->



    <!-- 录用流程内容开始 -->
    <el-dialog :title="operation5 ? '新增录用' : '修改录用'" :visible.sync="SakaryVisible5" v-model="SakaryVisible5" :close-on-click-modal="false">
      <el-form ref="SakaryForm5" :model="SakaryForm5" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label="面试ID:" prop="InterviewId">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.InterviewId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="面试结果ID:" prop="InterviewId">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.InterviewResultId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="应聘登记表ID:" prop="name_Guid">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.name_Guid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="录用人员:" prop="Name">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="录用人员ID:" prop="userid">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.userid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="考勤组ID:" prop="AttendID">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.AttendID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择考勤组" prop="Attend" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
            <el-input type="text" :disabled="true" v-model="SakaryForm5.Attend">
                <el-button slot="append" icon="el-icon-search" @click="selectAddress"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="工作部门:" prop="User_DPTnew">
              <el-input type="text" v-model="SakaryForm5.User_DPTnew"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="工作部门ID:" prop="User_DPTID">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.User_DPTID"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label="公司:" prop="GS">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.GS"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="职务类型ID:" prop="jobtype">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.jobtype"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="职务类型:" prop="jobtypename">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.jobtypename"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工作岗位:" prop="Post">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.Post"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="工作岗位ID:" prop="Postid">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.Postid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="薪资类型:" prop="SalaryType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm5.SalaryType" placeholder="薪资类型" style="width: 100%" @change="selectSalaryType()">
                <!-- <el-option label="基本工资" value="基本工资"></el-option> -->
                <el-option label="能源工程公司" value="能源工程公司"></el-option>
                <el-option label="计件工资" value="计件工资"></el-option>
                <el-option label="不计入工资" value="不计入工资"></el-option>
                <el-option label="后勤新入职" value="后勤新入职"></el-option>
                <!-- <el-option label="离职" value="离职"></el-option> -->
                <!-- <el-option label="驻外人员" value="驻外人员"></el-option> -->
                <!-- <el-option label="产检/长假" value="产检/长假"></el-option> -->
                <el-option label="日工资" value="日工资"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="试用期:" prop="ProbationExplain" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.ProbationExplain" :disabled="disProbation"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="StartTime" label="到岗时间:" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm5.StartTime" :disabled="disStart" value-format="yyyy-MM-dd" placeholder="选择日期" style="“width: 100%”"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="StartDate" label="试用开始时间:" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm5.StartDate" :disabled="disdate" value-format="yyyy-MM-dd" placeholder="选择日期" style="“width: 100%”"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="EndDate" label="试用结束时间:" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-date-picker type="date" v-model="SakaryForm5.EndDate" :disabled="disEnd" value-format="yyyy-MM-dd" placeholder="选择日期" style="“width: 100%”"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.WorkSalary" :disabled="disWork" @blur="inputMoney('WorkSalary')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="转正工资:" prop="PositiveExplain" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" :disabled="disPositive" v-model="SakaryForm5.PositiveExplain"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="职务工资:" prop="Post_treatment" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm5.Post_treatment"  :disabled="disPost" placeholder="职务工资" style="width: 100%" @change="selectTreatment()">
                <el-option label="无" value="无"></el-option>
                <el-option label="300" value="300"></el-option>
                <el-option label="400" value="400"></el-option>
                <el-option label="500" value="500"></el-option>
                <el-option label="600" value="600"></el-option>
                <el-option label="800" value="800"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="职务工资ID:" prop="Post_treatmentid">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.Post_treatmentid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有年功:" prop="IsYear" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <!-- <el-input type="text" v-model="SakaryForm5.IsYear"></el-input> -->
              <el-select v-model="SakaryForm5.IsYear" :disabled="disYear" placeholder="是否有年功" style="width: 100%">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label="超龄年功工资:" prop="Over_age" @blur="inputMoney('Over_age')">
              <el-input type="text" v-model="SakaryForm5.Over_age"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日工资:" prop="DaySalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.DaySalary" :disabled="disDay" @blur="inputMoney('DaySalary')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="伙食日工资:" prop="EateDaySalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm5.EateDaySalary" :disabled="disEate" placeholder="伙食日工资" style="width: 100%">
                <el-option label="0" value="0"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="35" value="35"></el-option>
                <el-option label="45" value="45"></el-option>
                <el-option label="60" value="60"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.WorkerMod" :disabled="disMod" @blur="inputMoney('WorkerMod')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日保健费:" prop="CareDaySalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.CareDaySalary" :disabled="disCare" @blur="inputMoney('CareDaySalary')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row> -->
          <!-- <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.OndutyCareSalary" @blur="inputMoney('OndutyCareSalary')"></el-input>
            </el-form-item>
          </el-col> -->
        <!-- </el-row> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="驻外补贴:" prop="OutsideSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.OutsideSalary" :disabled="disOut" @blur="inputMoney('OutsideSalary')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="特岗补贴:" prop="SpecialSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" :disabled="dis" v-model="SakaryForm5.SpecialSalary" @blur="inputMoney('SpecialSalary')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input type="text" v-model="SakaryForm5.HouseSalary" @blur="inputMoney('HouseSalary')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" style="display:none;">
            <el-form-item label="车辆补贴:" prop="BusMoney">
              <el-input type="text" v-model="SakaryForm5.BusMoney" @blur="inputMoney('BusMoney')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.PerfSalary" :disabled="disPer" @blur="inputMoney('PerfSalary')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="爱心基金:" prop="LoveMoney" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm5.LoveMoney" :disabled="disLove" @blur="inputMoney('LoveMoney')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否住宿:" prop="sfzs" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm5.sfzs" placeholder="是否住宿" :disabled="dissfzs" style="width: 100%">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display:none;">
            <el-form-item label="薪资ID:" prop="SalaryTypeid">
              <el-input type="text" :disabled="true" v-model="SakaryForm5.SalaryTypeid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否缴纳社保:" prop="PaySecurity" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm5.PaySecurity" placeholder="是否缴纳社保" :disabled="disPay" style="width: 100%">
                <el-option label="入职缴纳" value="入职缴纳"></el-option>
                <el-option label="转正缴纳" value="转正缴纳"></el-option>
                <el-option label="不缴纳" value="不缴纳"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="其他待遇:" prop="OtherBenefits">
              <el-input type="text" v-model="SakaryForm5.OtherBenefits"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input type="text" v-model="SakaryForm5.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit5('SakaryForm5')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 录用流程内容结束 -->

    <el-dialog :visible.sync="addressVisible" v-model="addressVisible" width="75%" title="考勤地点" center append-to-body>
      <el-table ref="multipleTable1" :data="AreaData" highlight-current-row max-height="300" style="width: 100%" @current-change="handleSelectionChange1">
        <el-table-column prop="Name" label="考勤组名称"> </el-table-column>
        <!-- <el-table-column label="类型" prop="Type"></el-table-column> -->
      </el-table>
      <div style="margin-top: 20px">
        <el-button v-on:click="areaSelection">确认选择</el-button>
      </div>
    </el-dialog>


    <externalOfferModal ref="externalOfferModal"></externalOfferModal>
  </section>
</template>
<script>
import {
  GetSalaInterlist,
  DelInterViewExaminer,
  AddInterViewExaminer,
  AddInterviewExtent,
  GetOrAddExtentInfo,
  AddProcess,
  GetSalaInterInfo,
  UpdataInterviewExtent,
} from "../../api/hr";
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import externalOfferModal from "../componentModals/externalOfferModalSala"    // 应聘登记表
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles,externalOfferModal },
  data() {
    return {
      disabled:true,
      addUserListVisible: false,
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      AreaData:[],
      addressVisible: false,
      SakaryVisible: false,
      SakaryVisible3: false,
      SakaryVisible4: false,
      SakaryVisible5: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation3:false,
      operation4:false,
      operation5:false,
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      PositionLists: [],
      dis:true,
      disPost:false,
      disEate:false,
      disCare:false,
      disOut:false,
      disPer:false,
      disLove:false,
      disYear:false,
      disWork:false,
      disStart:false,
      disPositive:false,
      disProbation:false,
      disdate:false,
      disEnd:false,
      disDay:false,
      disMod:false,
      dissfzs:false,
      disPay:false,
      selUserInfo:{
        interviewExtentId:"",//面试通知ID
        userId:"",//面试官ID
        userName:"",//面试官姓名
        content:"",//面试内容
      },
      SakaryForm5: {
        Attend: null,//考勤组
        AttendID: null,//考勤组ID
        User_DPTnew:null,//工作部门
        User_DPTID:null,//工作部门ID
        GS:null,//公司
        Post:null,//工作岗位
        Postid:null,//工作岗位ID
        ProbationExplain:null,//试用期
        StartDate:null,//开始时间
        EndDate:null,//结束时间
        WorkSalary:null,//岗位工资
        PositiveExplain:null,//转正工资
        Post_treatment:null,//职务工资
        Post_treatmentid:null,//职务工资ID
        IsYear:null,//是否有年功
        Over_age:null,//超龄年功工资
        DaySalary:null,//日工资
        WorkerMod:null,//工人系数
        EateDaySalary:null,//伙食日工资
        CareDaySalary:null,//日保健费
        OndutyCareSalary:0,//值班日保健费
        OutsideSalary:null,//驻外补贴
        HouseSalary:null,//租房补贴
        SpecialSalary:null,//特岗补贴
        BusMoney:null,//车辆补贴
        PerfSalary:null,//绩效工资
        LoveMoney:null,//爱心基金
        StartTime:null,//到岗时间
        sfzs:null,//是否住宿
        SalaryType:null,//薪资类型
        SalaryTypeid:null,//薪资类型ID
        PaySecurity:null,//是否缴纳社保
        OtherBenefits:null,//其他待遇
        Remark:null,//备注
        jobtype:null,//职务类型
        jobtypename:null,//职务类型
        userid:null,//录用人员ID
        Name:null,//录用人员姓名
        InterviewId:null,//面试ID
        name_Guid:null,//应聘登记表ID
        InterviewResultId:null,//面试结果ID
      },
      SakaryForm: {
        Name: null,
        date: null,
        content:null,
        userName:null,
        interviewExtentId:null,
        ApplyId:null,
        ExaminerArry: [],
        FileArry: [],
      },
      SakaryForm1: {
        CareerDevelopment: null,
        StressTolerance: null,
        Communication:null,
        Result:null,
        interviewExtentId:null,
        DetailArry: [
          {
          name: "专业知识",
          Testitem: "",
          Score: "0",
          Appraise:"",
          isShow: true,
          isShow2: true,
          isShow1: false,
        },
        {
          name: "专业技能操作",
          Testitem: "",
          Score: "0",
          Appraise:"",
          isShow: true,
          isShow2: true,
          isShow1: false,
        }
        ],
      },
      SakaryForm2: {
        CareerDevelopment: null,
        StressTolerance: null,
        Communication:null,
        Result:null,
        interviewExtentId:null,
          Summary: "",
        isHire:null,
        Id:null,
        DetailArry: [
          {
          name: "专业知识",
          Testitem: "",
          Score: "0",
          Appraise:"",
          // isShow: true,
        },
        {
          name: "专业技能操作",
          Testitem: "",
          Score: "0",
          Appraise:"",
          // isShow: true,
        }
        ],
      },
      SakaryUserForm: {
        UserId: null,
        userName: null,
        Factory: null,
        Dpt: null,
        Post: null,
        PostId: null,
          optType: null,
          content:null,
          InterviewType:null,
      },
      selectForm: {
        sketch: "",
        person: "",
      },
    };
  },
  methods: {
    /* 编辑 */
    handleEdit1(index, row) {
      row.isShow = false;
      row.isShow2 = false;
      row.isShow1 = true;
    },
    /* 保存 */
    hold(index, row) {
      row.isShow = true;
      row.isShow2 = true;
      row.isShow1 = false;
    },
    handleSelectionChange1(val) {//考勤组
      this.multipleSelection1 = val;
    },
    selectAddress() {//选择考勤组
      let that = this;
      this.$api.GetAllAttendGroup().then((res) => {
        this.AreaData = res.data.response;
        this.addressVisible = true;
        
      });
    },
    areaSelection() {//确认选择考勤组
        this.SakaryForm5.Attend = this.multipleSelection1.Name
        this.SakaryForm5.AttendID = this.multipleSelection1.Id
      this.addressVisible = false;
     
    },
    selectTreatment() {//选择职务工资
      if(this.SakaryForm5.Post_treatment=="无"){
        this.SakaryForm5.Post_treatmentid='0';
      }else if(this.SakaryForm5.Post_treatment=="300"){
        this.SakaryForm5.Post_treatmentid='39f722c9-7a8b-5d57-c217-b46c87167db5';
      }else if(this.SakaryForm5.Post_treatment=="400"){
        this.SakaryForm5.Post_treatmentid='39f722c9-7a8b-2ea7-1a61-5d38c36b32a8';
      }else if(this.SakaryForm5.Post_treatment=="500"){
        this.SakaryForm5.Post_treatmentid='39f722c9-7a8b-4763-1305-8b2dafff36be';
      }else if(this.SakaryForm5.Post_treatment=="600"){
        this.SakaryForm5.Post_treatmentid='39f722c9-7a8b-edec-7a2a-df6a0fc21f95';
      }else if(this.SakaryForm5.Post_treatment=="800"){
        this.SakaryForm5.Post_treatmentid='39f722c9-7a8b-aa0b-336f-2579602822cc';
      }
     
    },
    selectSalaryType(){//薪资类型
      if(this.SakaryForm5.SalaryType=="基本工资"){
        this.SakaryForm5.SalaryTypeid='1';
      }else if(this.SakaryForm5.SalaryType=="能源工程公司"){
        this.SakaryForm5.SalaryTypeid='2';
        this.SakaryForm5.Post_treatmentid='0';
        this.SakaryForm5.Post_treatment="无";
        this.disPost=true;
        this.SakaryForm5.EateDaySalary="0"
        this.disEate=true;
        this.SakaryForm5.CareDaySalary="0";
        this.disCare=true;
        this.SakaryForm5.OutsideSalary="0";
        this.disOut=true;
        this.SakaryForm5.SpecialSalary="0";
        this.dis=true;
        this.SakaryForm5.PerfSalary="0";
        this.disPer=true;
        this.SakaryForm5.LoveMoney="0";
        this.disLove=true;
        this.SakaryForm5.IsYear="否";
        this.disYear=true;

        // this.disPost=false;
        // this.disEate=false;
        // this.disCare=false;
        // this.disOut=false;
        // this.dis=false;
        // this.disPer=false;
        // this.disLove=false;
        // this.disYear=false;
        this.disWork=false;
        this.disStart=false;
        this.disPositive=false;
        this.disProbation=false;
        this.disdate=false;
        this.disEnd=false;
        this.disDay=false;
        this.disMod=false;
        this.dissfzs=false;
        this.disPay=false;


      }else if(this.SakaryForm5.SalaryType=="计件工资"){
        this.SakaryForm5.SalaryTypeid='3';

        this.SakaryForm5.WorkSalary="0";
        this.disWork=true;
        this.SakaryForm5.PositiveExplain="0";
        this.disPositive=true;
        this.SakaryForm5.Post_treatment="无";
        this.SakaryForm5.Post_treatmentid='0';
        this.disPost=true;
        this.SakaryForm5.EateDaySalary="0";
        this.disEate=true;
        this.SakaryForm5.OutsideSalary="0";
        this.disOut=true;
        this.SakaryForm5.SpecialSalary="0";
        this.dis=true;
        this.SakaryForm5.PerfSalary="0";
        this.disPer=true;
        this.SakaryForm5.LoveMoney="0";
        this.disLove=true;
        this.SakaryForm5.IsYear="否";
        this.disYear=true;

        this.SakaryForm5.WorkerMod="1";
        this.disMod=true;

        // this.disPost=false;
        // this.disEate=false;
        this.disCare=false;
        // this.disOut=false;
        // this.dis=false;
        // this.disPer=false;
        // this.disLove=false;
        // this.disYear=false;
        // this.disWork=false;
        this.disStart=false;
        // this.disPositive=false;
        this.disProbation=false;
        this.disdate=false;
        this.disEnd=false;
        this.disDay=false;
        this.dissfzs=false;
        this.disPay=false;


      }else if(this.SakaryForm5.SalaryType=="不计入工资"){
        this.SakaryForm5.SalaryTypeid='4';

        this.SakaryForm5.WorkSalary="0";
        this.disWork=true;
        this.SakaryForm5.PositiveExplain="0";
        this.disPositive=true;
        this.SakaryForm5.Post_treatment="无";
        this.SakaryForm5.Post_treatmentid='0';
        this.disPost=true;
        this.SakaryForm5.EateDaySalary="0";
        this.disEate=true;
        this.SakaryForm5.OutsideSalary="0";
        this.disOut=true;
        this.SakaryForm5.SpecialSalary="0";
        this.dis=true;
        this.SakaryForm5.PerfSalary="0";
        this.disPer=true;
        this.SakaryForm5.LoveMoney="0";
        this.disLove=true;
        this.SakaryForm5.IsYear="否";
        this.disYear=true;
        this.SakaryForm5.DaySalary="0";
        this.disDay=true;
        this.SakaryForm5.WorkerMod="0";
        this.disMod=true;
        this.SakaryForm5.CareDaySalary="0";
        this.disCare=true;
        this.SakaryForm5.sfzs="否";
        this.dissfzs=true;
        this.SakaryForm5.PaySecurity="不缴纳";
        this.disPay=true;

      }else if(this.SakaryForm5.SalaryType=="后勤新入职"){
        this.SakaryForm5.SalaryTypeid='5';

        this.SakaryForm5.WorkerMod="0";
        this.disMod=true;

        this.SakaryForm5.DaySalary="0";
        this.disDay=true;

        this.SakaryForm5.CareDaySalary="0";
        this.disCare=true;

        this.disPost=false;
        this.disEate=false;
        // this.disCare=false;
        this.disOut=false;
        // this.dis=false;
        this.disPer=false;
        this.disLove=false;
        this.disYear=false;
        this.disWork=false;
        this.disStart=false;
        this.disPositive=false;
        this.disProbation=false;
        this.disdate=false;
        this.disEnd=false;
        // this.disDay=false;
        // this.disMod=false;
        this.dissfzs=false;
        this.disPay=false;

      }else if(this.SakaryForm5.SalaryType=="离职"){
        this.SakaryForm5.SalaryTypeid='6';
      }else if(this.SakaryForm5.SalaryType=="驻外人员"){
        this.SakaryForm5.SalaryTypeid='7';
      }else if(this.SakaryForm5.SalaryType=="产检/长假"){
        this.SakaryForm5.SalaryTypeid='8';
      }else if(this.SakaryForm5.SalaryType=="日工资"){
        this.SakaryForm5.SalaryTypeid='9';

        this.SakaryForm5.WorkSalary="0";
        this.disWork=true;
        // this.SakaryForm5.PositiveExplain="0";
        this.disPositive=false;
        this.SakaryForm5.Post_treatment="无";
        this.SakaryForm5.Post_treatmentid='0';
        this.disPost=true;
        this.SakaryForm5.EateDaySalary="0";
        this.disEate=true;
        this.SakaryForm5.OutsideSalary="0";
        this.disOut=true;
        this.SakaryForm5.SpecialSalary="0";
        this.dis=true;
        this.SakaryForm5.PerfSalary="0";
        this.disPer=true;
        this.SakaryForm5.LoveMoney="0";
        this.disLove=true;
        this.SakaryForm5.IsYear="否";
        this.disYear=true;
        // this.SakaryForm5.DaySalary="0";
        this.disDay=false;
        this.SakaryForm5.WorkerMod="0";
        this.disMod=true;
        // this.SakaryForm5.CareDaySalary="0";
        this.disCare=false;
        this.SakaryForm5.sfzs="否";
        this.dissfzs=true;
        this.SakaryForm5.PaySecurity="不缴纳";
        this.disPay=true;

      }
    },
    selectInterviewType(){
      console.log(this.SakaryUserForm.InterviewType)
      if(this.SakaryUserForm.InterviewType=="1"){
        this.SakaryUserForm.content='基本要求面试';
      }else if(this.SakaryUserForm.InterviewType=="2"){
        this.SakaryUserForm.content='专业技能操作';
      }else if(this.SakaryUserForm.InterviewType=="3"){
        this.SakaryUserForm.content='专业知识';
      }
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm5[name])) {
        this.$message({
          message: "请输入数字类型",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm5[name] = null;
        return;
      }
    },
    PositionModel(id) {
      console.log(this.PositionLists);
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源
        if (item.PostName === id) {
          return item;
        }
      });

      this.SakaryUserForm.DptId = obj.DepartmentId;
      this.SakaryUserForm.PostId = obj.PostId;
      this.SakaryUserForm.Factory = obj.OrganizationName;
      this.SakaryUserForm.Dpt = obj.DepartmentName;
      this.SakaryUserForm.Post = obj.PostName;
          this.SakaryUserForm.UserPostId = obj.UserPostId;
      //  this.PermissionForm.PostSalaryId = obj.Id
    },

    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    PersonsDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.SakaryForm.ExaminerArry.splice(this.SakaryForm.ExaminerArry.indexOf(row), 1);
        let para = {
          interViewExaminerId: row.Id,
        };
        DelInterViewExaminer(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
          });
          // GetExpectationInfo({ recruitingId: this.SakaryForm.REV_CID }).then((res) => {
          //   console.log(res);
          //   this.SakaryForm = res.data.response;
          //   this.SakaryVisible = true;
          // });
          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      console.log(newdata[0].Id);
      // this.SakaryUserForm.UserId = newdata[0].Id;
      this.SakaryUserForm.UserId = newdata[0].Id;//
      this.SakaryUserForm.userName = newdata[0].UserNameClaim;
      this.$api.GetUserPostDptOrg({ userId: newdata[0].Id, onlyPrincipal: false }).then(
        (res) => {
          this.PositionLists = res.data.response;
        }
      );

      this.addUserVisible = false;
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    chooseUserList() {
      (this.SakaryUserForm = {
        UserId: null,
        userName: null,
        Factory: null,
        Dpt: null,
        Post: null,
        PostId: null,
        optType: null,
        content:null,
        InterviewType:null,
      }),
        (this.addUserListVisible = true);
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    onUserSubmit() {//添加面试官
      this.selUserInfo = {
        
        interviewExtentId:this.SakaryForm.interviewExtentId,//面试通知ID
        // userId:this.SakaryUserForm.UserId,//面试官ID
        userId:this.SakaryUserForm.UserId,//面试官ID
        userName:this.SakaryUserForm.userName,//面试官姓名
        content:this.SakaryUserForm.content,//面试内容
        InterviewType:this.SakaryUserForm.InterviewType,//面试内容类型
      }
      let para = Object.assign({}, this.selUserInfo);
      AddInterViewExaminer(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
          });
          this.getData();
          // console.log(this.SakaryForm.interviewExtentId)
          this.$api.GetSalaInterInfo({ id: this.SakaryForm.interviewExtentId }).then((res) => {
            this.SakaryForm.ExaminerArry = res.data.response.ExaminerArry;
            this.SakaryVisible = true;

          });

          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });

      this.addUserListVisible = false;
    },
    //获取列表
    // getData(isCurrent = true) {
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        person: this.selectForm.person,
        sketch: this.selectForm.sketch,
      };
      GetSalaInterlist(para).then((res) => {
      // this.$api.GetSalaInterlist(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    addInterview() {//面试
      let row = this.currentRow;
      // console.log(row.InterviewExtentId);
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      

      // 添加或获取面试ID开始
      this.selUserInfo = {
        applyId:row.ApplyId,//ApplyId
        interviewResultId:row.Id,//ID
      }
      let para = Object.assign({}, this.selUserInfo);
      GetOrAddExtentInfo(para).then((res) => {
          console.log(res.data.response.Id)//面试通知ID
          if (res.data.success) {
          //   this.$message({
          //     message: "添加或获取面试ID成功",
          //     type: "success",
          //     duration: 5000,
          // });
          this.SakaryForm.interviewExtentId=res.data.response.Id;
          // this.getData();
          } else {
            this.$message({
              message: "添加或获取面试ID失败",
              type: "error",
              duration: 5000,
            });
          }
        });
        // 添加或获取面试ID结束

      
      this.$api.GetSalaInterInfo({ id: row.InterviewExtentId }).then((res) => {
        this.getData();
        // console.log(res.data.response);
        // if(res.data.response.ApplyInfo==null||res.data.response.ApplyInfo==undefined){
        if(res.data.response==null||res.data.response==undefined){
          this.SakaryForm.Name = row.Name;
          this.SakaryForm.interviewExtentId = row.InterviewExtentId;
          this.SakaryForm.ApplyId = row.ApplyId;
        }else{
          this.SakaryForm = res.data.response;
          // this.key += 1;
          this.SakaryForm.Name = row.Name;
          this.SakaryForm.interviewExtentId = row.InterviewExtentId;
          this.SakaryForm.ApplyId = row.ApplyId;
        }
        
        this.SakaryVisible = true;
      });
      

      this.operation = false;
      this.operation1 = true;
    },
    handleEdit(){   //面试官评价
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      // console.log(row.InterviewExtentId);
      if(row.isInterviewer==true){//是面试官

        this.$api.GetSalaInterInfo({id:row.InterviewExtentId}).then((res)=>{
          if(res.data.response.ExaminerArry.length=='0'){
            this.$message({
              message: "该人员未分配面试官，无法进行面试官评价！",
              type: "error",
            });
          }else{
            console.log(row.InterviewExtentId);
            this.$api.GetInterviewAssessmentInfo({ id: row.InterviewExtentId }).then((res) => {
              // console.log(res.data.response);
              if (res.data.response==null){
                
                this.$api.GetInterviewAssessmentPermission({ interviewExtentId: row.InterviewExtentId }).then((res) => {
                  console.log(res.data.response);
                  if(res.data.response.opt01==false){
                    this.disabled=true;
                  }else{
                    this.disabled=false;
                  }
                  if(res.data.response.opt02==false){
                    this.SakaryForm1.DetailArry[0].isShow=false;
                  }else{
                    this.SakaryForm1.DetailArry[0].isShow=true;
                    
                  }
                  if(res.data.response.opt03==false){
                    this.SakaryForm1.DetailArry[1].isShow=false;
                  }else{
                    this.SakaryForm1.DetailArry[1].isShow=true;
                  }
                });

                this.SakaryForm1= {
                  interviewExtentId : row.InterviewExtentId,
                  CareerDevelopment:"",
                  Communication:"",
                  Id:"",
                  Result:"",
                  StressTolerance:"",
                  DetailArry:[
                    {
                    name: "专业技能操作",
                    Testitem: "",
                    Score: "0",
                    Appraise:"",
                    isShow: true,
                    isShow2: true,
                    isShow1: false,
                  },
                  {
                    name: "专业知识",
                    Testitem: "",
                    Score: "0",
                    Appraise:"",
                    isShow: true,
                    isShow2: true,
                    isShow1: false,
                  }
                  ]
                };
                this.operation3 = true;
              }else{

                this.$api.GetInterviewAssessmentPermission({ interviewExtentId: row.InterviewExtentId }).then((res) => {
                  console.log(res.data.response);
                  console.log(this.SakaryForm1.DetailArry[0].name);
                  console.log(this.SakaryForm1.DetailArry[1].name);
                  if(res.data.response.opt01==false){
                    this.disabled=true;
                  }else{
                    this.disabled=false;
                  }
                  // if(res.data.response.opt02==false){
                  //   this.SakaryForm1.DetailArry[0].isShow=false;
                  // }else{
                  //   this.SakaryForm1.DetailArry[0].isShow=true;
                  // }
                  // if(res.data.response.opt03==false){
                  //   this.SakaryForm1.DetailArry[1].isShow=false;
                  // }else{
                  //   this.SakaryForm1.DetailArry[1].isShow=true;
                  // }

                  if(res.data.response.opt02==false && res.data.response.opt03==true){
                    if(this.SakaryForm1.DetailArry[0].name=="专业技能操作"){
                      this.SakaryForm1.DetailArry[0].isShow=false;
                      this.SakaryForm1.DetailArry[1].isShow=true;
                    }
                    if(this.SakaryForm1.DetailArry[0].name=="专业知识"){
                      this.SakaryForm1.DetailArry[0].isShow=true;
                      this.SakaryForm1.DetailArry[1].isShow=false;
                    }
                  }
                  if(res.data.response.opt02==true && res.data.response.opt03==false){
                    if(this.SakaryForm1.DetailArry[0].name=="专业技能操作"){
                      this.SakaryForm1.DetailArry[0].isShow=true;
                      this.SakaryForm1.DetailArry[1].isShow=false;
                    }
                    if(this.SakaryForm1.DetailArry[0].name=="专业知识"){
                      this.SakaryForm1.DetailArry[0].isShow=false;
                      this.SakaryForm1.DetailArry[1].isShow=true;
                    }
                  }
                  if(res.data.response.opt02==true && res.data.response.opt03==true){
                    if(this.SakaryForm1.DetailArry[0].name=="专业技能操作"){
                      this.SakaryForm1.DetailArry[0].isShow=true;
                      this.SakaryForm1.DetailArry[1].isShow=true;
                    }
                    if(this.SakaryForm1.DetailArry[0].name=="专业知识"){
                      this.SakaryForm1.DetailArry[0].isShow=true;
                      this.SakaryForm1.DetailArry[1].isShow=true;
                    }
                  }
                  if(res.data.response.opt02==false && res.data.response.opt03==false){
                    if(this.SakaryForm1.DetailArry[0].name=="专业技能操作"){
                      this.SakaryForm1.DetailArry[0].isShow=false;
                      this.SakaryForm1.DetailArry[1].isShow=false;
                    }
                    if(this.SakaryForm1.DetailArry[0].name=="专业知识"){
                      this.SakaryForm1.DetailArry[0].isShow=false;
                      this.SakaryForm1.DetailArry[1].isShow=false;
                    }
                  }
                });

                this.SakaryForm1= {
                  interviewExtentId : row.InterviewExtentId,
                  CareerDevelopment:res.data.response.CareerDevelopment,
                  Communication:res.data.response.Communication,
                  Id:res.data.response.Id,
                  Result:res.data.response.Result,
                  StressTolerance:res.data.response.StressTolerance,
                  DetailArry:[
                    {
                    name: res.data.response.DetailArry[0].name,
                    Testitem: res.data.response.DetailArry[0].Testitem,
                    Score: res.data.response.DetailArry[0].Score,
                    Appraise:res.data.response.DetailArry[0].Appraise,
                    isShow: true,
                    isShow2: true,
                    isShow1: false,
                  },
                  {
                    name: res.data.response.DetailArry[1].name,
                    Testitem: res.data.response.DetailArry[1].Testitem,
                    Score: res.data.response.DetailArry[1].Score,
                    Appraise:res.data.response.DetailArry[1].Appraise,
                    isShow: true,
                    isShow2: true,
                    isShow1: false,
                  }
                  ]
                };
                this.operation3 = false;
              }

            });
            this.SakaryVisible3 = true;
            this.operation1 = true;
            
              }
        });
        
      }else{
        this.$message({
          message: "您无权限进行面试官评价！",
          type: "error",
        });
      }
      

    },

    AddInterviewDpt(){   //用人部门评价
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row.isResponsibility);
      if(row.isResponsibility==true){
        // console.log(row.InterviewExtentId)
        this.$api.GetInterviewAssessmentInfo({ id: row.InterviewExtentId }).then((res) => {
          // console.log(res.data.response);
          if (res.data.response==null){
            this.SakaryForm2= {
              interviewExtentId : row.InterviewExtentId,
              CareerDevelopment:"",
                Communication: "",
                Summary: "",
              // Id:"",
              Result:"",
              StressTolerance:"",
              DetailArry:[
                {
                name: "专业技能操作",
                Testitem: "",
                Score: "0",
                Appraise:"",
                isShow: true,
                isShow2: true,
                isShow1: false,
              },
              {
                name: "专业知识",
                Testitem: "",
                Score: "0",
                Appraise:"",
                isShow: true,
                isShow2: true,
                isShow1: false,
              }
              ]
            };
            // this.operation4 = true;
          }else{
            this.SakaryForm2= {
              interviewExtentId : row.InterviewExtentId,
              CareerDevelopment:res.data.response.CareerDevelopment,
              Communication:res.data.response.Communication,
              // Id:res.data.response.Id,
              Result:res.data.response.Result,
                StressTolerance: res.data.response.StressTolerance,
                Summary: "",
              DetailArry:[
                {
                name: res.data.response.DetailArry[0].name,
                Testitem: res.data.response.DetailArry[0].Testitem,
                Score: res.data.response.DetailArry[0].Score,
                Appraise:res.data.response.DetailArry[0].Appraise,
                isShow: true,
                isShow2: true,
                isShow1: false,
              },
              {
                name: res.data.response.DetailArry[1].name,
                Testitem: res.data.response.DetailArry[1].Testitem,
                Score: res.data.response.DetailArry[1].Score,
                Appraise:res.data.response.DetailArry[1].Appraise,
                isShow: true,
                isShow2: true,
                isShow1: false,
              }
              ]
            };
          }
          this.$api.GetInterviewDptInfo({ id: row.InterviewDptId }).then((res1) => {
            // console.log(res1.data.response);
            if (res1.data.response==null||res1.data.response==undefined){
              // this.SakaryForm2.interviewExtentId=row.InterviewExtentId;
              // this.SakaryForm2.Id=res.data.response.Id;
              this.operation4 = true;
            }else{
                console.log("res1.data.response",res1.data.response);
              this.SakaryForm2.Id=res1.data.response.Id;
                this.SakaryForm2.Summary = res1.data.response.Summary;
              // this.SakaryForm2.isHire=res1.data.response.isHire;
              this.$set(this.SakaryForm2, 'isHire', res1.data.response.isHire)

              //this.getData();
              this.operation4 = false;
            }
            
          });
        });
        
        this.SakaryVisible4 = true;
        this.operation1 = true;
      }else{
        this.$message({
          message: "您无权限填写用人部门评价！",
          type: "error",
        });
      }
    },


    ApplyUser(){   //录用流程
      let row = this.currentRow;
      // console.log(row);
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.SakaryForm5.SpecialSalary="0";
      this.SakaryForm5.BusMoney="0";
      this.SakaryForm5.HouseSalary="0";
      this.SakaryForm5.Over_age="0";
      this.SakaryForm5.Post=row.Positions;
      this.SakaryForm5.Postid=row.PositionsId;
      this.SakaryForm5.InterviewId=row.InterviewExtentId;
      this.SakaryForm5.name_Guid=row.ApplyId;
      this.SakaryForm5.Name=row.Name;
      this.SakaryForm5.InterviewResultId=row.Id
      if(row.Positions=="司机"){
        this.dis=false;
      }else{
        this.dis=true;
      }
      if(row.isResponsibility==true){
        
        this.$api.GetInterviewDptInfo({ id: row.InterviewDptId }).then((res) => {
          // console.log(res.data.response);
          if(res.data.response==null||res.data.response==undefined){
            this.$message({
              message: "未进行用人部门评价，无法发起录用！",
              type: "error",
            });
          }else{
            
            if(res.data.response.isHire==true){
              
            }else{
              this.$message({
                message: "该人员未通过面试，无法发起录用！",
                type: "error",
              });
              return;
            }
            if(row.ProcessType=="未发起"){

            }else{
              // this.$message({
              //   message: "已发起录用流程，无法再次发起！",
              //   type: "error",
              // });
              // return;
            }
            this.$api.getUserBaseInfo({ id: row.ApplyId }).then((res) => {
              console.log(res.data.response);
              if(res.data.response==""||res.data.response==null||res.data.response==undefined){
                this.$message({
                  message: "未关联人员详情信息，无法发起录用！",
                  type: "error",
                });
                return;
              }
            });

            this.SakaryVisible5 = true;
            this.operation5 = true;
          }
        });
      }else{
        this.$message({
          message: "您无权限发起录用流程！",
          type: "error",
        });
      }
    },

    onSubmit5(formName) {//触发录用审批流程
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.SakaryForm5.OndutyCareSalary=0;
            let para = Object.assign({}, this.SakaryForm5);
            if (this.operation5) {
              //新增
              AddProcess(para).then((res) => {
                console.log(res)
                if (res.data.success) {
                  this.$message({
                    message: "成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible5 = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              // UpdateCertificate(para).then((res) => {
              //   if (res.data.success) {
              //     this.$message({
              //       message: "修改成功",
              //       type: "success",
              //       duration: 5000,
              //     });
              //     this.SakaryVisible = false;
              //     this.getData(false);
              //   } else {
              //     this.$message({
              //       message: "修改失败",
              //       type: "error",
              //       duration: 5000,
              //     });
              //   }
              // });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },

    handleShow() {//查看
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.$refs.externalOfferModal.onShowDlg(row.ApplyId,row.RegisterId);
    },
    handleAdd() {
      (this.SakaryForm = {
        Name: null,
        date: null,
        content:null,
        userName:null,
        interviewExtentId:null,
        ExaminerArry: [],
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
      onSubmit(formName) {
        this.selUserInfo = {
            Id: this.SakaryForm.interviewExtentId,
            interviewResultId:this.SakaryForm.interviewResultId,
            ApplyId:this.SakaryForm.ApplyId,//ApplyId
            date:this.SakaryForm.date,//面试时间
      }
      let para = Object.assign({}, this.selUserInfo);
      // AddInterviewExtent(para).then((res) => {
      UpdataInterviewExtent(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "成功",
              type: "success",
              duration: 5000,
          });
          } else {
            this.$message({
              message: "失败",
              type: "error",
              duration: 5000,
            });
          }
        });
        this.SakaryVisible= false;
    },

    // 添加面试官评价
    onSubmit3(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm1);
            if (this.operation3) {
              //新增
              this.$api.AddInterviewAssessment(para).then((res) => {
                // console.log(res);
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible3 = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              this.$api.UpDataInterviewAssessment(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible3 = true;
                  this.getData(false);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    // 添加面试官评价结束

    // 添加用人部门评价
    onSubmit4(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // let para = Object.assign({}, this.SakaryForm2);
            let para = {
              summary: this.SakaryForm2.Summary,
              isHire: this.SakaryForm2.isHire,
              interviewExtentId: this.SakaryForm2.interviewExtentId,
            };
            if (this.operation4) {
              //新增
              this.$api.AddInterviewDpt(para).then((res) => {
                // console.log(res);
                if (res.data) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible4 = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
              this.SakaryVisible4 = false;
            }
             else {
              //修改
              let para = {
                summary: this.SakaryForm2.Summary,
                isHire: this.SakaryForm2.isHire,
                id: this.SakaryForm2.Id,
              };
              this.$api.UpDataInterviewDpt(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible4 = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
              this.SakaryVisible4 = false;
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    // 添加用人部门评价结束

    dealFiles(data) {
      this.SakaryForm.Files = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

